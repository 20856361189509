import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
      access_token: null,
      paginate_limit: 20,
      user: null,
      kds: "",
      menus: [],
  }),
  actions: {
    resetState() {
      this.$reset();
    },
    async setKds(kds) {
      this.kds = kds;
    },
    async setKdsUser(user) {
      this.user = user;
    },
    async getKdsDetail(form_data) {
      //we passes pin/type
      let response = await axios.post('/api/display/verification',form_data);
      response = response.data;
      if (response.status_code == 200) {
        this.kds = response.data.code;
      }
      return response;
    },
    async getUserDetail(form_data){
      //we passes pin/login_code/type
      let response = await axios.post('/api/login',form_data);
      response = response.data;
      if (response.status_code == 200) {
        this.access_token = response.data.access_token;
        this.paginate_limit = response.data.paginate_limit;
        this.user = response.data.user;
        this.menus = response.data.menus;
        if(response.data.user.language_id == 1)
          localStorage.setItem('language','en');
        else
          localStorage.setItem('language','ar');
      }
      return response;
    },
    async logout(form_data){
      await axios.post('/api/logout',form_data);
      this.reset();
      return true;
    },
    reset(){
      localStorage.removeItem('language');
      this.access_token = this.kds = this.user = null;
      this.menus = [];
      return false;
    }
  },
  getters: {
    authenticated() {
      if(this.access_token != null && this.access_token != ''){
        return true;
      }else if(this.kds!=null){
        return false;
      }else {
        this.reset();
        return false;
      }
    },
  },
  persist : true
})