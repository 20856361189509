const kitchenRoutes = [
    {
        path : '/kitchen',
        component : () => import('@/pages/restaurant/kitchen.vue'),
        name : 'Kitchen Screen',
        meta : {
            key : 'SM_KITCHEN',
            authRequired : true,
        },
    },
    
];

export default kitchenRoutes;